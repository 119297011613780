import { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "../../components/dashboard/dataTable";
import Nav from "../../components/dashboard/nav";
import ModalData from '../../components/modalData';
import arrowDown from '../../assets/icons/arrowdown.svg';

const getAmbassadors = async () => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/ambassadors`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admintoken')}` }
    });
    return result;
}
const getStats = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/ambassadors/stats/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admintoken')}` }
    });
}
const verify = async (id) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/ambassadors/verify/${id}`, null, {
        headers: { Authorization: `Bearer ${localStorage.getItem('admintoken')}` }
    });
}
export default function Ambassadors() {
    const [modal, setModal] = useState(false);
    const [ambassadors, setAmbassadors] = useState([]);
    const [ambassadorId, setAmbassadorId] = useState(null);
    const [stats, setStats] = useState(null);
    const [verifyStatus, setVerifyStatus] = useState('idle');
    const ambassadorWithName = ambassadors?.map(ambassador => ({ ...ambassador, name: `${ambassador.firstName} ${ambassador.lastName}` }));
    const chosenAmbassador = ambassadorWithName.find(el => el.id === ambassadorId);
    const actionsFormatter = (cell, row, rowIndex, formatExtraData) => {
        function handleClick() {
            setAmbassadorId(row.id);
            setModal(true);
        }
        return (<button className="ambassador-more" data-toggle="modal" data-target={`#ambassador`} onClick={handleClick}>more <img src={arrowDown} alt='icon' height={20} width={21} /></button>)
    };
    const statusFormatter = (cell, row) => {
        console.log(row, '=== row ======')
        return <div className={`ambassador-status ${row.isVerified ? 'ambassador-verified': null}`}>{row.isVerified ? 'verified' : 'unverified'}</div>
    }
    function handleModal() {
        setModal(false);
        setVerifyStatus('idle');
    }
    function verifyAmbassador() {
        setVerifyStatus('verifying');
        verify(ambassadorId).then(response => {
            setVerifyStatus('verified');
        })

    }
    const column = [{
        dataField: 'id',
        text: 'ID',
        sort: true
    }, {
        dataField: 'name',
        text: 'Name',
        sort: true
    },
    {
        dataField: 'region',
        text: 'Region',
        sort: true
    },
    {
        dataField: 'isVerified',
        text: 'Status',
        isDummyField: true,
        formatter: statusFormatter,
    },
    {
        dataField: 'more',
        text: 'More details',
        isDummyField: true,
        formatter: actionsFormatter
    }]
    useEffect(() => {
        getAmbassadors().then(response => {
            console.log(response);
            setAmbassadors(response.data.data);
        })
    }, []);
    useEffect(() => {
        if(verifyStatus === 'verified') {
            getAmbassadors().then(response => {
            console.log(response);
            setAmbassadors(response.data.data);
        })
        } else {
            return;
        }
        
    }, [verifyStatus]);
    useEffect(() => {
        if(!ambassadorId) return;
        getStats(ambassadorId).then(response => {
            setStats(response.data.data);
        })
    }, [ambassadorId])
    return <>
        <div className="container-fluid text-body ambassador-wrapper">
            <Nav page="Ambassadors" />
            {modal ? <ModalData size={20} id='ambassador' title={chosenAmbassador.name} footer='ambassador footer' handleModalCloseClick={handleModal}>
                <h2>Profile</h2>
                <div className="modal-details">
                    <div className="modal-name">
                        <p className="ambassador-info">name </p>
                        <p className="ambassador-info">email </p>
                        <p className="ambassador-info">status </p>
                        <p className="ambassador-info">promo code </p>
                    </div>
                    <div>
                        <p className="ambassador-details">{chosenAmbassador.name}</p>
                        <p className="ambassador-details">{chosenAmbassador.email}</p>
                        <p className="ambassador-details">{chosenAmbassador.isVerified ? 'verified' : 'unverified'}</p>
                        <p className="ambassador-details">{chosenAmbassador.ambassadorId ? chosenAmbassador.ambassadorId : 'not yet given'}</p>
                    </div>
                </div>
                {!chosenAmbassador.isVerified ? <div class='verify-ambassador'>
                    <p>The ambassadors is not yet verified,<br />
                        click below to verify them</p>
                    <button onClick={verifyAmbassador}>{verifyStatus === 'idle' ? 'verify' : verifyStatus}</button>
                </div> : null
                }
                {chosenAmbassador.isVerified ? <>
                    <h2>stats</h2>
                    <div className="modal-details">
                        <div className="modal-name">
                            <p className="ambassador-info">today </p>
                            <p className="ambassador-info">this month </p>
                            <p className="ambassador-info">this year </p>
                            <p className="ambassador-info">total</p>
                        </div>
                        <div>
                            <p className="ambassador-details">{stats?.today || 0}</p>
                            <p className="ambassador-details">{stats?.montly || 0}</p>
                            <p className="ambassador-details">{stats?.yearly || 0}</p>
                            <p className="ambassador-details">{stats?.total || 0}</p>
                        </div>
                    </div></> : null}
            </ModalData> : null}
            <div className="px-1">
                <DataTable rows={ambassadorWithName} columns={column} />
            </div>
        </div>
    </>
}