/* eslint-disable react/style-prop-object */
import axios from 'axios';
import React, { Component } from 'react';
import Nav from '../../components/dashboard/nav';
import DataTable from '../../components/dashboard/dataTable';

class Videos extends Component {

	state = {
		videos: { rows: [], columns: [] },
		video: '',
		isUploading: false,
	}

	componentDidMount() {
		this.getAllVideos()
	}

	fileChangeHandler = (e) => {
		this.setState({ [e.target.name]: e.target.files[0] })
	}

	approveVideo = (id) => {
		this.setState({ isUploading: true })

		axios.post(`${process.env.REACT_APP_API_URL}/series/create?id=${id}`, null, {
			headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}`, 'Content-Type': 'multipart/form-data' }
		})
			.then(response => {
				alert("Episode Approved SuccessFully !")
				this.setState({ isUploading: false })
				this.getAllVideos()
			})
			.catch(error => {
				alert("An Error Occured")
				this.setState({ isUploading: false })
			});
	}

	deleteVideo = (id) => {
		this.setState({ isUploading: true })

		axios.post(`${process.env.REACT_APP_API_URL}/series/delete/${id}`, null, {
			headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}`, 'Content-Type': 'multipart/form-data' }
		})
			.then(response => {
				alert("Episode Deleted SuccessFully !")
				this.setState({ isUploading: false })
				this.getAllVideos();
			})
			.catch(error => {
				alert(`An Error Occured, ${error.message}`)
				this.setState({ isUploading: false })
			});
	}

	getTempVideo = (id) => {
		axios.get(`${process.env.REACT_APP_API_URL}/temp/series?id=${id}`, {
			headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
		})
			.then((res) => {
				console.log(res);
				window.open(res.data.data.uri, '_blank');
			})
			.catch((err)=> {
				alert(err.response);
				console.log(err);
			})
	}
	deleteEpisode = (id) => {
		console.log(window.localStorage.getItem('admintoken'))
		axios.delete(`${process.env.REACT_APP_API_URL}/series/delete/temp/${id}`, {
			headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
		})
			.then((res) => {
				alert("Episode deleted SuccessFully !")
				this.setState({ isUploading: false })
				this.getAllVideos()
			})
			.catch((err)=> {
				console.log(err);
				alert(err.response);
				
			})
	}

	getAllVideos = () => {
		const actionsFormatter = (cell, row) => {
			return (
				<>
					<div className="row px-2">
						<div className="btn btn-primary col mx-1" onClick={() => this.approveVideo(row.id)}><i style={{color:"lightgreen"}} className="fas fa-thumbs-up"></i> Approve</div>
						<div className="btn btn-primary col mx-1" onClick={()=>{
							 this.deleteEpisode(row.id)
							// alert("coming soon")
							 }}><i style={{color:"lightblue"}} className="fas fa-trash"></i> Delete </div> 
						<div onClick={()=> this.getTempVideo(row.id)} className="btn btn-secondary col mx-1">  <i className="fas fa-eye"></i>Video</div>
					</div>
				</>
			)
		};
		axios.get(`${process.env.REACT_APP_API_URL}/temp/series/all`, {
			headers: { Authorization: `Bearer ${window.localStorage.getItem('admintoken')}` }
		})
			.then((res) => {
				const videos = {
					rows: res.data.data, columns: [{
						dataField: 'id',
						text: 'ID',
						sort: true
					}, {
						dataField: 'videoId',
						text: 'Episode Name',
						sort: true
					},
					{
						dataField: 'videoSuspensionState',
						text: 'Action',
						isDummyField: true,
						formatter: actionsFormatter
					}, {
						dataField: 'createdAt',
						text: 'Created',
						sort: true
					}]
				}
				this.setState({ videos });

			})
			.catch(err => {
				console.log(err.response)
				this.setState({ videos: { columns: null } })
			})
	}
	render() {
		return (
			<div>
				<div className="container-fluid text-body">
					<Nav page="Temporaly Series" />
					{this.state.isUploading ? (
						<div className="alert alert-primary alert-dismissible fade show" role="alert">
							<div className="spinner-grow mr-2" role="status">
								<span className="sr-only">Loading...</span>
							</div> Uploading Video to Cinetie Servers...
							<button type="button" className="close" data-dismiss="alert" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					) : null}
					<div className="row px-3">
						<DataTable rows={this.state.videos.rows} columns={this.state.videos.columns} />
					</div>

				</div>
			</div>
		)
	}
}

export default Videos