import React, { useState } from 'react';
import '../../../styles/components/restrictions.scss';

export default ({ handler,allCountries,Alignment }) => {
    const [ allConts, setAllConts ] = useState
    ([
        {name:'Africa',status:false},
        {name:'Europe',status:false}, 
        {name:'Asia',status:false},
        {name:'Americas',status:false}, 
        {name:'Oceania',status:false}
    ])  
    const [ restrictedConts,setRestrictedConts ] = useState([]);
    
    return (
        <div className="restrictions">
            <h5>Allowed Regions <i className="fas fa-globe-africa"></i></h5> 
            <hr />
            <section >
                <div style={{ display:Alignment }}>
                    { Alignment !== "grid" && <h5>Continental :</h5>}
                    <div className="continents">
                        { allConts[0] &&
                             allConts.map((cont,index)=>{
                                const [ edit,setEdit ] = useState(false)
                                    return (
                                        <p className="cont" key={index}>
                                            <span style={{background: cont.status?"red":"green"}} onClick={async ()=>{
                                                const exist  = restrictedConts.find(el => el === cont.name)
                                                if(!exist){
                                                    setRestrictedConts([...restrictedConts,cont.name])
                                                }else{
                                                    setRestrictedConts(preConts=> preConts.filter(el => el !== cont.name))
                                                }
                                                }}>
                                                    <i onClick={()=>setEdit(!edit)} className={ !edit ? "tiny fas fa-pen" : " tiny fas fa-times"}></i>
                                                    <strong>Restrict Some In {cont.name}</strong>
                                            </span>
                                            <span style={{background: cont.status?"red":"green"}} >{cont.name}</span>
                                            { !cont.status ?
                                                <span onClick={()=>{
                                                    handler("Grant",[cont.name]);
                                                    const newConts = allConts.map(el=>{
                                                            if(el.name !== cont.name){
                                                                return el
                                                            }else{
                                                                return { name:cont.name,status:true }
                                                            }
                                                        });
                                                    setAllConts(newConts);
                                                }} style={{background:"green"}}><i className="tiny fas fa-trash"></i><strong>Restrict All of {cont.name}</strong></span> :
                                                <span style={{background:"red"}} onClick={async ()=>{
                                                    handler("Revoke",[cont.name]);
                                                    const newConts = allConts.map(el=>{
                                                        if(el.name !== cont.name){
                                                            return el
                                                        }else{
                                                            return { name:cont.name,status:false }
                                                        }
                                                        });
                                                    setAllConts(newConts);
                                                }}><i className="fas fa-check"></i><strong>Restrict All of {cont.name}</strong></span>
                                            }
                                        </p>
                                    )
                                }
                            ) 
                        }
                    </div>
                </div>
                <div className="countries">
                    { restrictedConts[0] && 
                        restrictedConts.map((cont,index)=>
                            <div key={index} className="specific">
                                <h5>{cont} : </h5>
                                <div className="continents second">
                                {  allCountries.map((country,index)=>{
                                    const clss = country.back === "green" ? true : false;
                                    return  (
                                    country.region === cont ?
                                        <section className="cont" key={index}>
                                            <span style={{background: country.back === "green" ? "red" : "green",color:"white"}}>{country.name.official}</span>
                                            {
                                                clss ?
                                            <span onClick={async()=>{
                                                handler( "Revoke",country.name.official)
                                                const newConts = allConts.map(el=>{
                                                    if(el.name !== country.region){
                                                        return el
                                                    }else{
                                                        return { name:country.region,status:false }
                                                    }
                                                   });
                                                setAllConts(newConts);
                                            }}> <i className="fas fa-check"></i> </span> :
                                            <span onClick={()=>{
                                                handler( "Grant",country.name)
                                            }}> x </span>
                                            }
                                        </section> : null
                                        )}
                                    ) 
                                }
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>
        </div>
    )
}
